<template>
  <div>
    <Search class="search-fixed" @setSelectStr="setSelectStr">
      <div slot="left" class="search-left">
        <van-icon
          name="arrow-left"
          size="20"
          color="#6f6f6f"
          @click="lastStep"
        />
      </div>
      <div slot="right" class="search-right">
        <van-button
          type="danger"
          round
          color="linear-gradient(to right,#FE5876 , #ED301D)"
          @click="searchGoods"
        >
          搜索
        </van-button>
      </div>
    </Search>
    <van-dropdown-menu class="dropdown-menu">
      <van-dropdown-item
        v-model="sequence"
        :options="option1"
        @change="menuUpdate"
      />
      <van-dropdown-item :title="areaValue" ref="region">
        <template #default>
          <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            active-color="#ED301D"
            @close="onClose"
            @finish="onFinish"
          />
        </template>
      </van-dropdown-item>
    </van-dropdown-menu>
    <!-- 商品列表 -->
    <div class="goods-list-module">
      <GoodsList :goodsFind="goodsFind"></GoodsList>
    </div>
  </div>
</template>

<script>
import Search from "@/components/search/search.vue";
import GoodsList from "../../components/goods-list/goods-list";
import region from "@/common/region/regionThree";
export default {
  name: "used-list",
  components: {
    Search,
    GoodsList,
  },
  data() {
    return {
      sequence: 1,
      cascaderValue: "",
      areaValue: "地区",
      option1: [
        { text: "综合", value: 1 },
        { text: "价格升序", value: 2 },
        { text: "价格降序", value: 3 },
      ],
      options: [],
      goodsFind: {
        order_by: 1,
        used_name: "",
        sell_id: "",
        area_id: "",
      },
      inputVal: "",
      regionArr: [],
    };
  },
  mounted() {
    region.unshift({ value: 0, text: "全部" });
    this.options = region;
  },
  methods: {
    onFinish({ value, selectedOptions, tabIndex }) {
      this.goodsFind.area_id = value;
      this.areaValue = selectedOptions[tabIndex].text;
      this.$refs.region.toggle();
    },
    onClose() {
      this.$refs.region.toggle();
    },
    setSelectStr(inputStr) {
      this.inputVal = inputStr;
    },
    searchGoods() {
      this.goodsFind.used_name = this.inputVal;
    },
    menuUpdate(menuVal) {
      this.goodsFind.order_by = menuVal;
    },
    lastStep() {
      // this.$router.push({ name: "home" });
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    region.shift();
  },
};
</script>

<style lang="less" scoped>
.search-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
}
.dropdown-menu {
  position: fixed;
  top: 58px;
  right: 0;
  left: 0;
  z-index: 2000;
  // margin-top: 58px;
}
/deep/.van-dropdown-menu__bar {
  box-shadow: none;
}
.van-grid-item {
  flex-basis: auto !important;
}
.goods-list-module {
  margin-top: 108px;
  padding-bottom: 64px;
}
/deep/.van-ellipsis {
  font-size: 18px;
  font-weight: 500;
}
/deep/.van-dropdown-item__content {
  border-radius: 0 0 10px 10px;
}
</style>